var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-tabs",
        {
          attrs: { shrink: "", stretch: "", dense: "", align: "left" },
          model: {
            value: _vm.nodeTab,
            callback: function ($$v) {
              _vm.nodeTab = $$v
            },
            expression: "nodeTab",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "q-pl-sm q-pr-sm",
              staticStyle: {
                background: "#B2DFDB",
                color: "#00695C",
                height: "100%",
                display: "flex",
                "align-items": "center",
              },
            },
            [
              _c(
                "b",
                [
                  _c("q-icon", { attrs: { name: "sms", size: "xs" } }),
                  _vm._v(" " + _vm._s(_vm.setLabel("LBL0001004")) + " "),
                ],
                1
              ),
            ]
          ),
          _vm._l(_vm.tabItems, function (tab, idx) {
            return _c(
              "keep-alive",
              { key: idx },
              [
                _c("q-tab", {
                  attrs: { name: tab.name, label: tab.label },
                  on: {
                    click: function ($event) {
                      return _vm.tabClick(tab)
                    },
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "q-list",
              { staticClass: "rounded-borders", attrs: { bordered: "" } },
              [
                _c(
                  "q-expansion-item",
                  {
                    attrs: {
                      dense: "",
                      dark: "",
                      "default-opened": "",
                      icon: "place",
                      "header-class": "bg-blue-grey-6 text-white",
                      "expand-icon-class": "text-white",
                      label: _vm.setLabel("LBL0001195"),
                    },
                  },
                  [
                    _c("q-separator"),
                    _c("q-card", { staticClass: "q-pa-sm" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-12 col-md-3" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "LBL0001090",
                                value: _vm.node.nodeNo,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-12 col-md-3" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "LBL0001004",
                                value: _vm.node.nodeTitle,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-12 col-md-4" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "LBL0001088",
                                value:
                                  _vm.node.startStudyDate +
                                  "~" +
                                  _vm.node.endStudyDate,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-12 col-md-6" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "LBL0001085",
                                value: _vm.node.nodeDesc,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-12 col-md-6" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "LBL0001086",
                                value: _vm.node.designConditions,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-12 col-md-6" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "LBL0001087",
                                value: _vm.node.operatingConditions,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-12 col-md-6" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "LBL0001015",
                                value: _vm.node.designIntent,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "LBL0001165",
            columns: _vm.grid.columns,
            gridHeight: _vm.grid.height,
            data: _vm.node.scenarios,
            merge: _vm.grid.merge,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            editable: _vm.editable && !_vm.disabled,
            customTrClass: _vm.setTrClass,
            selection: "multiple",
            rowKey: "ramKpsrAssessScenarioId",
          },
          on: { innerBtnClicked: _vm.innerBtnClicked },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "customCol"
                    ? [
                        _c(_vm.imprComponent, {
                          tag: "component",
                          attrs: {
                            col: col,
                            props: props,
                            inputEditable:
                              _vm.editable &&
                              Boolean(props.row.ramKpsrAssessScenarioId),
                            requestContentsCols: _vm.requestContentsCols,
                            tableKey: "ramKpsrAssessScenarioId",
                            ibmTaskTypeCd: "ITT0000005",
                            ibmTaskUnderTypeCd: "ITTU000005",
                          },
                          on: { imprChange: _vm.imprChange },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isCompared,
                        expression: "isCompared",
                      },
                    ],
                    attrs: { label: "LBL0001166", icon: "compare_arrows" },
                    on: { btnClicked: _vm.compareScenario },
                  }),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBL0001199", icon: "add" },
                        on: { btnClicked: _vm.addRiskType },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBLREMOVE", icon: "remove" },
                        on: { btnClicked: _vm.removeScenario },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.node.scenarios,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveScenario,
                          btnCallback: _vm.saveScenarioCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("c-win-pop", {
        ref: "winPopup",
        on: {
          onClose: (val) => _vm.evtCloseWinPopup(val),
          onRecvEvtFromWinPop: (val) => _vm.onRecvWinPop(val),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }